
import { defineComponent, onMounted, ComputedRef, ref, computed } from 'vue'
import formatCurrency from '@/filters/CurrencyFormat'
import checkUserSettings from '@/mixins/checkUserSettings'
import Button from '@/components/UI/Button.vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { ActionTypes as userCarsActionTypes } from '@/store/userCars/actions'
import { ActionTypes } from '@/store/options/actions'
import { AuthActionTypes } from '@/store/auth/actions'
import { MutationTypes } from '@/store/options/mutations'
import ConvertToCommas from '@/filters/ConvertToCommas'
import { savedCarsType } from '@/types'
import Loader from '@/components/UI/Loader.vue'
import Popup from '@/components/Popup.vue'
import InfoPopup from '@/components/UI/InfoPopup.vue'
import { USER_STATUS } from '@/composables/staticValues'
import toLowerCase from '@/filters/toLowerCase'
export default defineComponent({
  name: 'Home',
  components: {
    Button,
    Checkbox,
    Loader,
    Popup,
    InfoPopup
  },
  setup() {
    const router = useRouter()
    const { displayUserSettingPopup, goToAccount } = checkUserSettings()
    const showPopup = ref(false)
    const store = useStore()
    const checkedSavedCars = ref<savedCarsType[]>([])
    const selectAllItems = ref({
      id: 1,
      isActive: true
    })
    const imagesURl = process.env.VUE_APP_IMG_URL
    const user = computed(() => store.state.auth.currentUser)
    const loader = computed(() => store.state.userCars.loader)
    const showNoCarPolicyPopup = computed(() => store.state.options.showPopUp)
    const allSavedCars: ComputedRef<savedCarsType[]> = computed(() => store.state.userCars.savedCars)
    const carOrderSuccess = computed(() => store.state.options.carOrderSuccess)
    const carOrderPdfError = computed(() => store.state.options.carOrderPdfError)
    const accessToken = computed(() => store.state.auth.accessToken)
    const initialize = async () => {
      if (accessToken.value && !displayUserSettingPopup.value) {
        await store.dispatch(userCarsActionTypes.GET_ALLSAVEDCARS, { status: 0 })
      }
    }
    onMounted(initialize)

    const launchSelector = () => {
      if (user.value.carPolicy !== null) {
        router.push({ name: 'Step1' })
      } else {
        store.commit(MutationTypes.SET_POPUP, true)
      }
    }
    const closePopup = () => {
      showPopup.value = false
    }
    const onProceed = () => {
      showPopup.value = false
      const carIds = checkedSavedCars.value.map((e) => {
        return e.id
      })
      store.dispatch(userCarsActionTypes.DELETE_SAVED_CAR, carIds)
      checkedSavedCars.value = []
    }
    const configureSelected = async () => {
      if (checkedSavedCars.value.length > 0) {
        // allOptionId's
        const optionIds = checkedSavedCars.value[0].options
          .flatMap((e) => {
            return [e, ...e['children']]
          })
          .map((item) => item['optionId'].toString())
        // userSelectedOptions
        const userSelectedOptions = checkedSavedCars.value[0].options.map((e) => {
          return e['optionId'].toString()
        })
        // nestedOptions
        const nestedOptions = {}
        checkedSavedCars.value[0].options.forEach((item) => {
          if (!nestedOptions[item['optionId']]) {
            nestedOptions[item['optionId']] = []
            item['children'].forEach((e) => nestedOptions[item['optionId']].push(e['optionId'].toString()))
          }
        })
        store.state.options.nestedOptions = nestedOptions
        sessionStorage.setItem('nestedOptions', JSON.stringify(nestedOptions))
        const selectedOptions = {
          selectedOptions: optionIds,
          userSelectedOptions: userSelectedOptions,
          requiredChoices: [],
          option: '',
          action: 'added',
          otherAccessories: checkedSavedCars.value[0].otherAccessories,
          chargingFacilities: checkedSavedCars.value[0].chargingFacilities
        }
        await sessionStorage.setItem('optionsConfigurationData', JSON.stringify(selectedOptions))
        await router.push({
          name: 'Step3',
          params: {
            vehicleId: checkedSavedCars.value[0].vehicleDetails.unique_identity_101,
            carOrderId: checkedSavedCars.value[0].id
          }
        })
      }
    }

    const viewDetails = async () => {
      store.dispatch(ActionTypes.DOWNLOAD_CARORDERPDF, {
        lang: localStorage.getItem('locale') || 'nl',
        carOrderId: user.value.carOrders['id']
      })
    }
    const compareSelected = () => {
      const carIds = checkedSavedCars.value.map((e) => {
        return e.id
      })
      router.push({ name: 'CompareCars', query: { id: carIds } })
    }

    const checkSelected = (item) => {
      const searchIndex = checkedSavedCars.value.findIndex((e: savedCarsType) => e.id == item.id)
      return searchIndex !== -1
    }
    const onCheck = (item) => {
      const searchIndex = checkedSavedCars.value.findIndex((e: savedCarsType) => e.id == item.id)
      if (searchIndex === -1) {
        checkedSavedCars.value.push(item)
      } else {
        checkedSavedCars.value.splice(searchIndex, 1)
      }
    }
    const closeInfoPopup = () => {
      store.commit(MutationTypes.SET_CARORDERSUCCESS, false)
    }
    const closePdfInfoPopup = () => {
      store.commit(MutationTypes.SET_CARORDERPDFERROR, false)
    }
    const closeShowNoCarPolicyPopup = () => {
      store.commit(MutationTypes.SET_POPUP, false)
    }
    const selectAll = (allItems, $event) => {
      checkedSavedCars.value = [...allItems]
      if (!$event.target.checked) {
        checkedSavedCars.value = []
      }
    }
    const getLeaseValidUntil = (date) => {
      return date.split('-').reverse().join('-')
    }
    return {
      launchSelector,
      showPopup,
      closePopup,
      onProceed,
      configureSelected,
      checkSelected,
      formatCurrency,
      onCheck,
      ConvertToCommas,
      allSavedCars,
      checkedSavedCars,
      loader,
      compareSelected,
      imagesURl,
      carOrderSuccess,
      carOrderPdfError,
      closeInfoPopup,
      closePdfInfoPopup,
      showNoCarPolicyPopup,
      closeShowNoCarPolicyPopup,
      selectAllItems,
      selectAll,
      viewDetails,
      user,
      getLeaseValidUntil,
      USER_STATUS,
      toLowerCase,
      displayUserSettingPopup,
      goToAccount
    }
  }
})
